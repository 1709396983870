//------------------------------------------ Responsive mixins

$small-mobile-width: 'only screen and (max-width : 360px)';
$mobile-width: 'only screen and (max-width : 576px)';
$tablet-width: 'only screen and (max-width : 768px)';
$desktop-width: 'only screen and (max-width : 992px)';

@mixin extra-small {
  @media #{$small-mobile-width} {
    @content;
  }
}

@mixin small {
  @media #{$mobile-width} {
    @content;
  }
}

@mixin medium {
  @media #{$tablet-width} {
    @content;
  }
}

@mixin large {
  @media #{$desktop-width} {
    @content;
  }
}
