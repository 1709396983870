@import 'theme/main.scss';

$logo-size: 135px;
$nav-icon-size: 30px;
$small-nav-icon-size: 20px;

@mixin mobile {
  @include media-breakpoint-down(md) {
    @content;
  }
}

.dashboard {
  z-index: 0;

  main {
    min-height: 92vh;

    @include mobile {
      min-height: 100vh;
    }

    a {
      color: black;
    }
  }

  .nav-icon {
    width: $nav-icon-size;
    height: $nav-icon-size;

    &:hover {
      cursor: pointer;
    }

    &-small {
      width: $small-nav-icon-size;
      height: $small-nav-icon-size;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
