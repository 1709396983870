@import 'theme/main.scss';

.consent-to-telehealth {
  padding: 15px;
  background-color: white;
  max-width: 400px;

  .text-transform-none {
    text-transform: none !important;
    letter-spacing: normal !important;
  }

  @include media-breakpoint-down(md) {
    max-width: 372px;
  }
}
