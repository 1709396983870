@import 'theme/main.scss';

.onboarding {
  background-color: $off-white;
  min-height: 100vh;

  .logo {
    padding-top: 30px;
    width: 100px;
    margin: auto;
  }
}
