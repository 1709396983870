@import 'theme/main.scss';

.how-did-you-hear {
  background-color: white;
  width: 372px;

  .sub-header,
  .info-text {
    color: $purple-darkest;
  }
  .sub-header {
    font-size: 14px !important;
    color: $blue-dark;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    font-weight: 500;
  }

  hr {
    width: 30px;
    border: 1px solid $gray-light;
  }

  .secondary-button {
    width: 320px;
    color: $blue-light;
    border: 1px solid $gray-light;
    margin: 0.25rem auto;
    padding: 10px;
    font-size: 1rem !important;

    &:hover,
    &:active,
    &-active {
      color: white !important;
      border: 1px solid $neon-blue-dark;
      background-color: $blue-light;
    }
  }

  .btn-outline-secondary:hover {
    color: white !important;
  }

  .link {
    color: $blue-dark;
    text-decoration: none !important;
  }
}
