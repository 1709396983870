@import 'theme/main.scss';

.myhealth-container {
  td {
    padding: 0.4rem;
  }

  .download-icon,
  .calendar-icon {
    width: 20px;
    height: 20px;

    &-spacer {
      width: 20px;
      height: 20px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled:hover {
      cursor: default;
    }
  }
}

.labs-container {
  max-width: 400px;
}

.upload-labs-form {
  .file-input {
    display: none;
  }

  .date-picker {
    max-width: 200px;
  }

  .loading-button {
    min-width: 100px;
    min-height: 36px;
  }
}

.goals-container {
  max-width: 50%;
  background-color: $gray-5;
  border-radius: 6px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  .goals-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .check-icon-container {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 15px;

    svg {
      width: 100%;
      height: 100%;
      fill: $green-80;
    }
  }
}

.insurance-container {
  max-width: 400px;
}

.appointment-container {
  max-width: 576px;

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: 'Inter-Medium';
  }
}
.appointment-note-modal {
  .note-text {
    text-wrap: wrap;
    overflow-x: auto;
  }
}
.gap-2 {
  gap: 1.5rem;
}
