@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';

@media screen and (min-width: 768px) {
.react-datepicker__navigation {
    padding: 1.5rem;
}

.react-datepicker {
    padding: 1rem 1rem 0rem 1rem;

.react-datepicker__month-container {
  background-color: "white";
  color: var(--gray-neutral);
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  text-align: center;

  .react-datepicker__header {
    background-color: white;
    border: none;
    padding: 0rem;
    font-size: 1rem;
    color: var(--gray-neutral);
  }

  .react-datepicker__month-text {
    display: inline-block;
    width: 5rem;
    margin: 0.5rem;
  }

  .react-datepicker__current-month {
    font-size: large;
  }

  .react-datepicker__day-name {
    color: var(--gray-neutral);
    width: 30px;
    margin-top: 1.25rem;
    justify-content: space-evenly;
    display: inline-flex;
  }

  .react-datepicker__header__dropdown {
    padding-top: .5rem;
  }

  .react-datepicker__header__dropdown{
    display: none;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    display: none;
  }



  .react-datepicker__week {
    display: flex;
    flex-wrap: wrap;
  }

  .react-datepicker__day {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: var(--blue-bright);
        cursor: pointer;
        color: white
    }
  }

  .react-datepicker__day--selected {
    background: var(--blue-light);
  }
}
}
}

.react-datepicker__input-container {
  padding-bottom: .5rem;
}

.react-datepicker__input-container > input {
  padding: 0.25rem;
}