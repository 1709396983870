@import 'theme/main.scss';

.payment {
  min-height: 100%;
  color: $blue-medium;

  .checkout-details {
    .h1 {
      font-size: 1.2rem;
      color: black;
    }

    .summary {
      background-color: white;
      padding: 20px;
      border-radius: 4px;
    }
  }

  .checkout-form {
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      width: 80%;
    }
    .header {
      font-size: 22px !important;
      color: $purple-darkest;
    }
    .text-style {
      color: $gray-600;
      font-family: Inter !important;
      line-height: 22px !important;
      letter-spacing: 1px !important;
      font-size: 14px;
      opacity: 0.5;
    }
    .subtitle {
      font-size: 16px !important;
      color: $purple-darkest;
      font-family: Inter !important;
      &-opacity {
        color: $gray-600 !important;
        opacity: 0.5;
      }
    }
    label {
      text-transform: capitalize !important;
      color: $gray-600;
      font-family: Inter !important;
      line-height: 22px !important;
      letter-spacing: 1px !important;
    }
    .text-transform-none {
      text-transform: none !important;
    }
    &-sub {
      width: 100%;
      flex-wrap: wrap;
      padding: 0px 40px;

      &-1 {
        background-color: white;
      }
      &-2 {
        background: radial-gradient(#ddf0f9 0%, #d0dcfc 100%);
      }
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }
  }

  .tnc-container {
    .form-check {
      height: min-content;
    }

    label {
      letter-spacing: 0;
      text-transform: none;
    }
  }

  .form {
    &-control {
      background-color: $off-white;
    }
  }

  .btn-block {
    max-width: 200px;
  }

  .checkout-button {
    &-arrow {
      margin-top: -6px;
      width: 18px;
      height: 18px;

      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: white;
        }
      }
    }

    &:disabled {
      .checkout-button-arrow {
        svg {
          path {
            stroke: $gray-dark;
          }
        }
      }
    }
  }

  .promo-input {
    background-color: white;
    width: 100% !important;
    margin: 0px;
  }

  .promo-code-apply-button {
    position: absolute !important;
    margin-top: 6px;
    right: 0px;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: none !important;
    }
  }
}

.card-element-container {
  height: 45px;
  display: flex;
  align-items: center;
  border: 2px solid #E2E8F0;
  border-radius: 5px;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
}
