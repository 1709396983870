$dot-size: 1.2rem;
$dot-size-sm: 0.6rem;
$left-margin: 5px;

@keyframes flow {
  0% {
    transform: translateX(calc(-400% - 15px)) scaleX(1.3) scaleY(1.2);
  }
  /* 50%{
      transform: translateX(calc(-250% - 7.5px)) scale(1.2);
    } */
  40% {
    transform: translateX(-100%) scaleX(1.3) scaleY(1.2);
  }

  100% {
    transform: scale(0);
  }
}

.loader {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;

  &-sm {
    left: calc(50% + 0.3rem);
  }

  &-md {
    left: calc(50% + 0.6rem);
  }
}

.dot {
  border-radius: 50%;
  margin-left: $left-margin;

  &-sm {
    width: $dot-size-sm;
    height: $dot-size-sm;
  }

  &-md {
    width: $dot-size;
    height: $dot-size;
  }
}

.dot:last-child {
  margin-left: 0;
  animation: 1.3s flow infinite ease-out;
}
