@import 'theme/main.scss';

.btn-primary {
  border-radius: 100px !important;
  &-green {
    color: white !important;
    border-color: $green-80 !important;
    background-color: $green-80 !important;

    &:hover,
    &:active,
    &:focus {
      border-color: $green-80 !important;
      background-color: $green-80 !important;
    }
  }

  &-dark-blue {
    color: white !important;
    border-color: $blue-dark !important;
    background-color: $blue-dark !important;

    &:hover,
    &:active,
    &:focus {
      border-color: $blue-dark !important;
      background-color: $blue-dark !important;
    }
  }
}

.secondary-button {
  border-radius: 100px !important;
  &-green {
    color: $green-80 !important;
    border-color: $green-80 !important;

    &:hover,
    &:active,
    &:focus {
      color: white !important;
      border-color: $green-80 !important;
      background-color: $green-80 !important;
    }
  }

  &-dark-blue {
    color: $blue-dark !important;
    border-color: $blue-dark !important;

    &:hover,
    &:active,
    &:focus {
      color: white !important;
      border-color: $blue-dark !important;
      background-color: $blue-dark !important;
    }
  }
}

.option-button {
  width: 100%;
  border-radius: 15px !important;
  text-transform: uppercase;

  svg {
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:active {
    color: white;
  }
}

.pill-button {
  color: white;
  background-image: linear-gradient(90deg, $blue-light, $gradient-day-end);
  border: none;
  border-radius: 100px;

  &:hover:enabled,
  &:active:enabled {
    background-image: none;
    background-color: $blue-light;
  }

  &:disabled {
    color: $gray-dark;
    background-image: none;
    background-color: $gray-light;
  }

  &-sm {
    height: 30px;
    min-width: 100px;
    padding: 0 10px;
  }

  &-lg {
    height: 60px;
    min-width: 250px;
    padding: 0 25px;
  }

  &-secondary {
    background-image: none;
    background-color: white;
    border: 1px solid $blue-medium;
    color: $blue-medium;

    &:hover:enabled,
    &:active:enabled {
      color: white;
      background-color: $blue-medium;
    }
  }

  &:hover,
  &:active {
    cursor: pointer;
  }
}
