@import 'theme/main.scss';

.messaging {
  .create-thread-container {
    margin: auto;
    max-width: 400px;

    .start-button {
      width: 100%;
    }
  }

  .thread-details-container {
    border-bottom: 2px solid $divider;
  }

  .thread-subject {
    letter-spacing: 0.1em;
  }

  .btn-primary {
    .plus-icon {
      margin-top: -3px;
      width: 18px;
      height: 18px;

      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: white;
        }
      }
    }
  }

  &-container {
    height: calc(100% - 115px);

    div {
      max-height: calc(100% - 50px);

      div {
        max-height: 100% !important;
      }
    }

    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }
}

.thread-preview {
  .unread {
    height: 8px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8px;
    border-radius: 50%;
    background-color: $green-80;
  }

  .flex-1 {
    flex: 1;
  }

  .thread-details {
    &-last-message-preview {
      &.color-gray-dark {
        color: $gray-dark;
      }
    }

    &-metadata {
      &-date {
        color: $gray-dark;
      }

      &-status {
        border-radius: 4px;
        padding: 1px 4px;
        text-transform: uppercase;

        &.open {
          background-color: #dbe1e4;
        }

        &.closed {
          background-color: antiquewhite;
        }
      }
    }
  }

  &.active {
    background-color: $gray-5;
  }
}
.custom-message {
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: #f6f5ef;
  }
}
