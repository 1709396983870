@import 'theme/main.scss';

.back-to-library {
  span {
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

.article-content {
  &-image {
    max-height: 45%;
    overflow: hidden;
  }

  &-related {
    &-container {
      background-color: $gray-5;
    }

    &-item {
      width: 300px;
      max-width: 300px !important;
      margin-left: 1.5rem;

      @include media-breakpoint-down(md) {
        width: 100%;
        max-width: 100% !important;
        margin-left: 0;
      }

      a {
        text-decoration: none;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        p {
          color: #000;
        }
      }

      &-image {
        height: 300px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
          height: auto;
        }

        img {
          object-fit: scale-down;
        }
      }
    }
  }

  &-footer {
    div {
      a {
        color: #000;
        text-decoration: none;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
