@import 'theme/main.scss';

.insurance-elegibility {
  background-color: white;
  width: 372px;

  .sub-header,
  .info-text {
    color: $purple-darkest;
  }

  .sub-header {
    font-size: 14px !important;
    color: $blue-dark;
    font-weight: 500;
  }

  .info-text {
    font-size: 14px !important;
    color: $blue-dark;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    font-weight: 500;
  }

  label {
    text-transform: capitalize !important;
    color: $gray-600;
    font-family: Inter !important;
    line-height: 22px !important;
    letter-spacing: 1px !important;
  }

  .width-100 {
    width: 100%;
  }
}
