@import 'theme/main.scss';

.insurance-covered {
  min-height: auto;
  overflow-y: auto;

  .card {
    max-width: 60%;
    border: none !important;
    &-image {
      width: 66px;
      padding: 5px;
      border-radius: 66px;
      background: radial-gradient(
        rgba(221, 240, 249, 1) 0%,
        rgba(208, 220, 252, 1) 100%
      );
    }
  }

  .spacer-1 {
    height: 1em;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    font-weight: 500;
  }

  .sub-header {
    font-size: 14px;
    color: $blue-dark;
  }

  .fw-400 {
    font-weight: 400;
  }

  .continue-button {
    width: auto;
    min-width: 200px;
    max-width: 90%;
    margin: 0 auto;
  }
}
