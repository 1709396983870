@import 'theme/main.scss';

$timeline-line-width: 0.5px;

.dashboard {
  &-col-l {
    padding-right: 15px !important;

    @include media-breakpoint-up(xl) {
      padding-right: 50px !important;
    }
  }

  &-card {
    background-color: $gray-5;
    border-radius: 5px;

    .form-padding {
      padding-left: 15px;
    }

    &-icon {
      width: 1.5em;
      height: 1.5em;

      &-container {
        margin-right: 1em !important;
      }
    }
  }

  &-mnc {
    button {
      width: 300px;
    }

    :first-child {
      max-width: 600px;
    }
  }

  .show-more-less-text {
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &:hover {
      cursor: pointer;
    }
  }

  .timeline {
    border: 1px solid $gray-40;
    border-radius: 7px;

    /* The actual timeline (the vertical ruler) */
    &-line {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;

      &::after {
        content: '';
        position: absolute;
        width: $timeline-line-width;
        background-color: $green-80;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: 0px;
      }
    }

    /* Container around content */
    .container {
      padding: 0;
      position: relative;
      background-color: inherit;
      width: 50%;

      &-spacer {
        min-height: 50px;
      }

      .info-icon {
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    /* Place the container to the left */
    .left {
      left: 0;
      margin: 0;
      text-align: right;

      /* Add underline */
      &::before {
        content: ' ';
        height: 0;
        position: absolute;
        bottom: 0;
        z-index: 1;
        right: 0;
        width: 70%;
        border-bottom: $timeline-line-width solid $green-80;
      }
    }

    /* Place the container to the right */
    .right {
      left: 50%;
      margin-left: 0;

      /* Add underline */
      &::before {
        content: ' ';
        height: 0;
        position: absolute;
        bottom: 0;
        z-index: 1;
        left: 0;
        width: 70%;
        border-bottom: $timeline-line-width solid $green-80;
      }
    }

    /* The actual content */
    .content {
      padding: 2px 8px;
      position: relative;

      &-info {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background: rgba(55, 113, 129, 0.65);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.multi-action-card {

  p {
    margin-bottom: 0;
    
    span:nth-of-type(2) {
      display: inline-block;
      margin-top: .5rem;
    }
  }
}
