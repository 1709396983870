.profile-container {
  .edit-icon {
    width: 35px;
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .btn-outline-secondary {
    color: black;
    border-color: black;

    &:hover,
    &:active {
      color: white;
      background-color: black;
    }
  }
}
