@import 'theme/main.scss';

$button-width: 100px;

.scheduling {
  height: 100vh;

  .next-button {
    min-width: $button-width;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;

    div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    svg {
      height: 14px;
      width: 14px;
    }

    @include media-breakpoint-down(md) {
      width: 90vw !important;
    }
  }

  .navigation {
    background-color: white;

    @include media-breakpoint-down(md) {
      height: auto;

      .hide {
        display: none !important;
      }
    }
    .check {
      border: 1px solid $yellow-allara;
      border-radius: 200px;
      width: 30px;
      height: 30px;

      svg path {
        fill: white !important;
        color: white !important;
      }

      &-filled {
        background-color: $yellow-allara !important;
        color: $blue-scale-800 !important;
      }

      @include media-breakpoint-down(md) {
        width: 20px;
        height: 20px;
        svg {
          width: 15px;
          height: 15px;
          transform: scale(0.6);
        }
      }
    }

    .vertical-line {
      height: 104px;
      width: 1px;
      background-color: $yellow-allara;
      &-active {
        background-color: $blue-scale-800;
      }
    }

    .horizontal-line {
      width: 45vw;
      height: 1px;
      background-color: $blue-scale-800;
      &-active {
        background-color: $blue-scale-800;
      }
    }

    .height-139 {
      height: 135px;
    }
  }

  .screen {
    background-color: $off-white;

    @include media-breakpoint-down(md) {
      height: 100vmax;
      background-color: $off-white;
      overflow-x: hidden;
    }

    &-container {
      margin-top: 85px;
      margin-left: 110px;
      margin-right: 25px;
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        margin: 0;
        padding: 24px 16px !important;
        width: calc(100vw - 32px) !important;
        background-color: $off-white;
      }

      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }

    .alert {
      max-width: 650px;
      background: linear-gradient(
          0deg,
          rgba(255, 78, 38, 0.17),
          rgba(255, 78, 38, 0.17)
        ),
        #ffffff;
      border-left: 4px solid $red-error;
      padding: 8px 8px 8px 12px;
      color: #b22a1b;

      a {
        color: #b22a1b;
        text-decoration: underline;

        &:hover {
          @include font-family-inter-medium;
        }
      }
    }

    .submit-button-container {
      width: $button-width;
      position: relative;

      @include media-breakpoint-down(md) {
        margin-right: 40px !important;
      }
    }

    .provider {
      min-width: 200px;

      .profile-picture {
        height: 44px;
        width: 44px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 44px;
        margin-bottom: 5px;

        &-initials {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          background-color: antiquewhite;
        }
      }

      h2 {
        color: $gray-600;
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .appointment-details {
      min-width: 325px;

      &-metadata {
        color: $gray-500;
      }
    }

    .location {
      max-width: 425px;

      .select {
        .form-label {
          text-transform: capitalize;
          letter-spacing: normal;
          font-size: 14px;
          color: $gray-600;
          @include font-family-inter;
        }
        @include media-breakpoint-down(md) {
          width: 90vw !important;
        }
      }
    }

    .confirm {
      .sub-title {
        color: $gray-600;
      }
    }

    .success {
      .modify-buttons {
        & > div {
          line-height: 21px;
          font-size: 14px;

          svg {
            height: 14px !important;
            width: 14px !important;
          }

          &:last-child {
            color: $red-error;
          }
        }

        & > a {
          color: black;
        }

        &:hover,
        &:active {
          cursor: pointer;
        }
      }
    }

    .legal-text {
      color: $gray-600;
      max-width: 650px;
    }

    .appointment-type-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 0.5rem;
      border: 1px solid $gray-500;
      width: 15rem;

      p {
        margin: 0;
      }

      .form-check {
        margin: 0;

        input {
          accent-color: $gray-500;
          &::after {
            border-color: $gray-500;
          }
        }
      }

      &:hover,
      &:active,
      &:focus {
        font-weight: bold;
        cursor: pointer;
      }

      &-selected {
        font-weight: bold;
      }
    }

    .appointment {
      background-color: $off-white;
      &-sub {
        flex-direction: row !important;
        flex-wrap: wrap !important;
        @include media-breakpoint-down(lg) {
          flex-direction: column !important;
        }
        @media (max-width: 1246px) {
          flex-direction: column !important;
        }
      }
      @include media-breakpoint-down(md) {
        table {
          width: 100vw;
        }
      }
      .provider-card-container {
        padding: 16px 20px;
        border-radius: 4px;
        border: 1px solid $gray-200;

        @include media-breakpoint-up(md) {
          padding-right: 89px; 
        }

        &:hover,
        &:active,
        &:focus {
          border: 1px solid $blue-scale-800;
          cursor: pointer;
        }

        &-selected {
          border: 1px solid $blue-scale-800 !important;
        }
      }

      .chip {
        border-radius: 5px;
        padding: 9px 5px;
        min-width: 49px;
        font-size: 14px;
        font-weight: 500;
        color: $blue-scale-800;
        display: flex;
        justify-content: center;
        align-items: center;

        &-picked {
          background-color: $blue-scale-800 !important;
          border: 1px solid $blue-scale-800;
        }

        &-slot {
          width: 109px;
        }
        &-with-border {
          border: 1px solid $gray-200;
        }

        &-no-border {
          border: none;
        }

        &-disabled {
          color: $gray-200;
          pointer-events: none;
        }
        &-clickable-disabled {
          color: $gray-200;
          &:hover {
            background-color: none;
            color: $blue-scale-800;
          }
        }

        &:hover,
        &:active,
        &:focus {
          cursor: pointer;
          background-color: $gray-200;
        }
      }

      .time-slot-chip {
        padding: 6px 5px;
      }
      tr {
        display: flex;
        justify-content: center;
      }

      &-slot {
        width: 109px;
      }
      &-disabled {
        th {
          padding: 9px 5px;
          min-width: 49px;
          color: $gray-200;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover,
        &:active,
        &:focus {
          cursor: pointer;
          background-color: $gray-200;
        }
      }

      .control-header {
        svg {
          color: $blue-5;
        }

        svg:hover {
          cursor: pointer;
        }
      }
      .gap-8 {
        gap: 8px;
        justify-content: center;
      }

      th {
        padding: 9px 5px;
        min-width: 49px;
        color: $gray-200;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      max-width: 820px;

      .provider-card-container {
        padding: 16px 20px;
        border-radius: 4px;
        border: 1px solid $gray-200;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid $blue-scale-800;
          cursor: pointer;
        }

        .selected {
          border: 1px solid $blue-scale-800;
        }
        .avatar {
          width: 44px;
          height: 44px;
        }
      }

      .control-header {
        svg:hover {
          cursor: pointer;
        }
        @include media-breakpoint-down(md) {
          margin: 20px 0px !important;
          gap: 40px;
        }
      }
      &-container-scheduling {
        width: 308px;

        .see-more-availability {
          color: $color-black;
          font-weight: 400;
        }

        .providers-list-container {
          max-height: 500px;
          overflow-y: auto;
        }

        @media (max-width: 1246px) {
          width: 311px;
          margin-top: 24px;
          margin-left: 0px !important;
          padding-left: 16px;
          padding-right: 16px;
        }

        @include media-breakpoint-down(md) {
          margin-left: 0px !important;
          align-self: center;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      .ml-59 {
        margin-left: 59px;
      }
    }
  }
}

.provider-image {
  margin-right: 4px;
}
