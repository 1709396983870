@import 'theme/main.scss';

.have-insurance {
  background-color: white;
  width: 372px;

  @include media-breakpoint-up(md) {
    height: 85vh;
  }

  .sub-header,
  .info-text {
    color: $purple-darkest;
  }
  .sub-header {
    font-size: 14px !important;
    color: $blue-dark;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    font-weight: 500;
    text-align: center;
  }
}
