@import './variables.scss';

.source-comms__message {
  &--outgoing &--content {
    background-color: $blue-5;
  }

  &-input {
    align-items: center;

    &--textarea {
      max-height: 200px;
      background-color: white;

      &:hover {
        cursor: text;
      }
    }

    &--send-button {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
