@import 'theme/main.scss';

.insurance-info {
  color: #000000;
  width: 90%;
  max-width: 700px;

  &-form {
    background-color: white;
    padding: 15px;
  }

  .upload-card {
    border: 1px dashed rgba(173, 175, 193, 1);
    background-color: rgba(247, 247, 252, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 154px;
    border-radius: 7px;
    cursor: pointer;
  }
  .header {
    font-size: 22px !important;
    color: $purple-darkest;
  }
  .sub-header {
    font-size: 14px !important;
    color: $blue-dark;
  }
  label {
    text-transform: capitalize !important;
    color: $gray-600;
    font-family: Inter !important;
    line-height: 22px !important;
    letter-spacing: 1px !important;
  }
  input[type='file'] {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .img {
    width: 90%;
    height: 100%;
    padding: 10px;
  }

  .black-text {
    color: black;
  }

  .underline {
    text-decoration: underline !important;
  }
}
