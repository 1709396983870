@import 'theme/main.scss';

.join-program-modal {
  .modal-body {
    min-height: 150px;
  }

  .feature-list {
    margin: auto;
    max-width: 350px;
  }

  .loading-button {
    margin: auto;
    width: 250px;
    min-height: 36px;
  }

  .feature-check-icon {
    margin-right: 10px;
    flex-basis: 18px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .pricing-plans {
    border: 1px solid $gray-light;
    border-radius: 20px;

    hr {
      border: 1px solid $gray-light;
    }

    &-savings {
      max-width: 100px;
      color: $blue-medium;
      background-color: $neon-blue-dark;
      border-radius: 50px;
      padding: 10px 15px;
    }
  }

  .mw-300 {
    width: 300px;
    margin: auto;
  }

  &-body {
    margin: auto;
    max-width: 450px;
  }
}
