.profile-picture {
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  &-sm {
    height: 35px;
    width: 35px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35px;
  }

  &-lg {
    height: 50px;
    width: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;
  }

  &-initials {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: antiquewhite;
  }
}
