@use "sass:map";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300&display=swap');
@import './variables';
@import './responsive';
@import './source';
@font-face {
  font-family: 'Canela';
  src: local('Canela'), url(../assets/fonts/Canela-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Ovo';
  src: local('Ovo'), url(../assets/fonts/Ovo-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'),
    url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'),
    url(../assets/fonts/Inter-Bold.ttf) format('truetype');
}

@import 'node_modules/bootstrap/scss/bootstrap';

@mixin spaced-capital-letters {
  letter-spacing: $letter-spacing-label;
  text-transform: uppercase;
}

@mixin form-label {
  font-size: map-get($map: $font-size, $key: 2);
  @include spaced-capital-letters();
}

@mixin heading1 {
  font-size: map-get($map: $font-size, $key: 10);
  font-weight: $font-normal;
  font-family: Canela;
  font-style: normal;
}

@mixin font-family-canela {
  font-family: Canela;
}

@mixin font-family-ovo {
  font-family: Ovo;
}

@mixin font-family-inter {
  font-family: Inter;
}

@mixin font-family-inter-medium {
  font-family: Inter-Medium;
}

@mixin font-family-inter-bold {
  font-family: Inter-Bold;
}

@mixin disabled {
  background-color: $color-disabled;
  border-color: $color-disabled;
}

@mixin primary-button-style {
  width: $primary-button-width;
  height: $primary-button-height;
  border-radius: $primary-button-border-radius;

  @include small {
    width: 100%;
  }
}

@mixin small-button-style {
  width: 115px;
  height: 37px;
  border-radius: 2px;
}

.primary-button {
  @include primary-button-style;
}

body {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: $font-light;
  color: $color-black;

  #root,
  .Container {
    height: inherit;
    padding: 0;
  }

  .h1 {
    @include heading1;
  }

  .h1-sub-text {
    font-size: map-get($map: $font-size, $key: 6);
  }

  .ff-canela {
    @include font-family-canela;
  }

  .ff-ovo {
    @include font-family-ovo;
  }

  .ff-inter {
    @include font-family-inter;
  }

  .ff-inter-medium {
    @include font-family-inter-medium;
  }

  .ff-inter-bold {
    @include font-family-inter-bold;
  }

  .text-left {
    text-align: left;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .h-100-vh {
    height: 100vh !important;
  }
}

.btn {
  &-primary {
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: white;
    }
  }

  &-block {
    width: 100%;
  }

  &-outline-secondary {
    border-radius: 8px;

    &:hover,
    &:active,
    &.active {
      color: white;
    }
  }

  &-text {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &:disabled {
    @include disabled;
  }
}

.half-width-buttons {
  & > div {
    padding: 5px;
    background-color: $blue-very-very-light;

    & > * {
      margin: 0px 5px 0px 5px;
      width: calc(50% - 15px);
    }
  }
}

.link {
  color: $link-color;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.spaced-capital-letters {
  @include spaced-capital-letters();
}

.bg-image {
  z-index: -1;
}

.sharing-logo {
  @include small {
    width: $social-logo-size-mobile;
    height: $social-logo-size-mobile;
  }
}

.progress {
  background-color: $gray-5;
  border-radius: 10px;
  height: 10px;

  &-bar {
    background-color: map-get($map: $theme-colors, $key: primary);
  }
}

:root {
  --inner-height: 100vh;
  --inner-height-md: 100vh;
  --inner-height-lg: 100vh;
}

.max-height-screen {
  max-height: var(--inner-height);
  min-height: var(--inner-height) !important;
  overflow: scroll;

  @include media-breakpoint-up(md) {
    min-height: var(--inner-height-md) !important;
  }

  @include media-breakpoint-up(lg) {
    min-height: var(--inner-height-md) !important;
  }
}

.min-height-full {
  min-height: 100%;
}

.height-inherit {
  height: inherit;
}

.height-0 {
  height: 0;
}

.height-full {
  height: 100%;
  box-sizing: border-box;
}

.width-full {
  width: 100%;
}

.width-fit-content {
  width: fit-content;
}

.content-box {
  box-sizing: content-box;
}

.border-box {
  box-sizing: border-box;
}

.font-weight-light {
  font-weight: $font-light;
}

.font-weight-normal {
  font-weight: $font-normal;
}

.font-weight-bold {
  font-weight: $font-bold;
}

.font-italic {
  font-style: italic;
}

.text-strike-through {
  text-decoration: line-through;
}

.form-group {
  .form-control {
    font-size: map-get($map: $font-size, $key: 4);
    font-weight: $font-light;
    color: $color-black;
    border-color: $color-black;
  }

  .form-label {
    @include form-label;
  }

  .dropdown-menu {
    width: 100%;
    border-color: $color-black;
    overflow-x: hidden;
  }

  .form-check {
    &-inline {
      display: flex;
      align-items: center;
    }

    &-label {
      font-size: map-get($map: $font-size, $key: 3);
      padding: map-get($map: $spacers, $key: 2);
      padding-left: map-get($map: $spacers, $key: 3);
    }

    &-input {
      border-color: $color-black;
      margin-block: 0;

      &:checked {
        background-color: map-get($map: $theme-colors, $key: primary);
      }

      &[type='checkbox'] {
        width: $checkbox-size;
        min-width: $checkbox-size;
        height: $checkbox-size;
      }

      &[type='radio'] {
        width: $radio-size;
        height: $radio-size;
      }
    }
  }
}

.spinner {
  &-border {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
  }
}

.justify-content-space-around {
  justify-content: space-around;
}

.auto-width {
  width: auto;
}

//----------------------------------------- Typography

@each $size, $value in $font-size {
  .fs-#{$size} {
    font-size: $value !important;
  }

  .fs-sm-#{$size} {
    @include media-breakpoint-up(sm) {
      font-size: $value !important;
    }
  }
}

@each $space, $value in $spacers {
  .height-#{$space} {
    height: $value;
    min-height: $value;
  }

  .height-sm-#{$space} {
    height: auto;
    @include media-breakpoint-up(sm) {
      height: $value !important;
      min-height: $value !important;
    }
  }

  .max-height-#{$space} {
    max-height: $value !important;
  }

  .min-height-#{$space} {
    min-height: $value !important;
  }

  .min-height-sm-#{$space} {
    min-height: auto;
    @include media-breakpoint-up(sm) {
      min-height: $value !important;
    }
  }

  .min-height-lg-#{$space} {
    min-height: auto;
    @include media-breakpoint-up(lg) {
      min-height: $value !important;
    }
  }

  .width-#{$space} {
    width: $value !important;
    min-width: $value !important;
  }

  .width-sm-#{$space} {
    width: auto;
    @include media-breakpoint-up(sm) {
      width: $value !important;
    }
  }

  .max-width-#{$space} {
    max-width: $value !important;
  }

  .max-width-sm-#{$space} {
    max-width: auto;

    @include media-breakpoint-up(sm) {
      max-width: $value !important;
    }
  }

  .min-width-#{$space} {
    min-width: $value !important;
  }

  .min-width-sm-#{$space} {
    min-width: auto;
    @include media-breakpoint-up(sm) {
      min-width: $value !important;
    }
  }

  .top-#{$space} {
    top: $value;
  }

  .top-md-#{$space} {
    top: auto;

    @include media-breakpoint-up(md) {
      top: $value !important;
    }
  }

  .top-lg-#{$space} {
    top: auto;

    @include media-breakpoint-up(lg) {
      top: $value !important;
    }
  }

  .right-#{$space} {
    right: $value;
  }

  .right-md-#{$space} {
    right: auto;

    @include media-breakpoint-up(md) {
      right: $value !important;
    }
  }

  .right-lg-#{$space} {
    right: auto;

    @include media-breakpoint-up(lg) {
      right: $value !important;
    }
  }

  .bottom-#{$space} {
    bottom: $value;
  }

  .bottom-md-#{$space} {
    bottom: auto;

    @include media-breakpoint-up(md) {
      bottom: $value !important;
    }
  }

  .bottom-lg-#{$space} {
    bottom: auto;

    @include media-breakpoint-up(lg) {
      bottom: $value !important;
    }
  }

  .left-#{$space} {
    left: $value;
  }

  .left-md-#{$space} {
    left: auto;

    @include media-breakpoint-up(md) {
      left: $value !important;
    }
  }

  .left-lg-#{$space} {
    left: auto;

    @include media-breakpoint-up(lg) {
      left: $value !important;
    }
  }

  .br-#{$space} {
    border-radius: $value;
  }
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
    padding-top: min(5vmin, env(safe-area-inset-top));
    padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}
