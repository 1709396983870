@import 'theme/main.scss';

.payment-plans {
  .header {
    font-size: 22px !important;
    color: $purple-darkest;
  }

  .color-gray-500 {
    color: $gray-500;
  }

  .pricing-plans {
    border: 1px solid $gray-light;
    width: 300px;
    margin: auto;
    border-radius: 20px;

    hr {
      border: 1px solid $gray-light;
    }

    &-savings {
      max-width: 100px;
      color: $blue-medium;
      background-color: $neon-blue-dark;
      border-radius: 50px;
      padding: 10px 15px;
    }
  }

  .plan-comparison {
    .left,
    .right {
      width: 150px;
    }

    &-header {
      height: 80px;

      .right {
        color: $gray-dark;
        border-radius: 0 20px 0 0;
        background-color: $gray-light;
      }

      .left {
        border-radius: 20px 0 0 0;
        background-image: linear-gradient(
          110deg,
          $blue-light,
          $gradient-day-end
        );

        img {
          width: 50%;
        }
      }
    }

    &-row {
      .left,
      .right {
        height: 100px;
        text-align: center;
        padding: 0 4px;
      }

      .right {
        color: $gray-medium;
        background-color: #fafafa;

        svg {
          width: 10px;
          height: 10px;

          path {
            fill: $gray-medium;
          }
        }
      }

      .left {
        color: $blue-medium;
        background-color: #f0f8ff;

        svg {
          width: 10px;
          height: 10px;

          path {
            fill: $blue-light;
          }
        }
      }

      &-title {
        height: 50px;
        color: $blue-medium;
        background-color: white;
      }
    }

    &-footer {
      height: 80px;

      .right {
        color: $gray-dark;
        border-radius: 0 0 20px 0;
        background-color: $gray-light;
      }

      .left {
        color: white;
        border-radius: 0 0 0 20px;
        background-image: linear-gradient(
          110deg,
          $blue-light,
          $gradient-day-end
        );
      }
    }
  }

  .continue-button {
    width: 90%;
    max-width: 300px;

    &-arrow {
      margin-top: -6px;
      width: 18px;
      height: 18px;

      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: white;
        }
      }
    }
  }

  .more-info-button {
    &-arrow {
      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: $blue-medium;
        }
      }
    }

    &:hover,
    &:active {
      .more-info-button-arrow {
        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }

  .back-button {
    top: 15px;
    left: 15px;
    color: $blue-medium;

    &:hover,
    &:active {
      cursor: pointer;
      border-bottom: 1px solid $blue-medium;
    }
  }

  .legal-text {
    max-width: 350px;
  }
}
.insurance-payment-plan {
  width: 372px;
  background-color: white;

  .fw-700 {
    font-weight: 700;
  }

  .text-color-gray-500 {
    color: $gray-500;
  }

  .tab {
    padding: 4px;
    background-color: rgba(205, 206, 223, 0.3);
    margin: 0px 28px;
    border-radius: 6px;
    .tab-item {
      width: 50%;
      padding: 4px;
      border-radius: 6px;
      cursor: pointer;
      &-selected {
        background-color: white;
      }
      .promo {
        background-color: $blue-5;
        padding: 3px 5px;
        font-size: smaller;
        color: white;
        border-radius: 4px;
      }
    }
  }
}
