@import 'theme/main.scss';

.direct-eligibility-container {
  .checkout-form {
    width: 33%;

    @include media-breakpoint-down(md) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 75%;
    }

    @include media-breakpoint-down(xs) {
      width: 95%;
    }
  }
}
