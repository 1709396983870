@import 'theme/main.scss';

.dots {
  margin-top: -16px;
  margin-bottom: 24px;
  .pagination-dot {
    height: 10px;
    width: 10px;
    margin: 0 4px;
    border: 1px solid $blue-medium;
    border-radius: 50%;

    &.active {
      background-color: $blue-medium;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.program-card {
  background-color: white;
  border-radius: 25px;
  width: 340px;
  margin: 0 8px;
  padding: 0 25px;
  position: relative;
  padding-bottom: 120px;
  font-family: 'Inter', 'Roboto', sans-serif;

  &-icon {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 100%;
    background-image: linear-gradient(
      180deg,
      $gradient-day-start,
      $gradient-day-end
    );

    img {
      width: 85%;
      height: 85%;
      margin: auto;
    }
  }

  &-feature {
    color: $gray-dark;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    padding-top: 75px;
  }

  .sub-header {
    color: $gray-dark;
    height: 42px;
  }

  .feature-check-icon {
    margin-right: 10px;
    flex-basis: 18px;
    flex-grow: 0;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: $blue-medium;
      }
    }
  }

  .recommended {
    &-pill {
      background-color: $purple-5;
      color: white;
      border-radius: 25px;
      width: 250px;
      margin: auto;
      height: 45px;
    }

    &-spacer {
      height: 45px;
    }
  }

  .continue-button {
    position: absolute;
    bottom: 1rem;
    width: 90%;
    max-width: 300px;

    &-arrow {
      margin-top: -6px;
      width: 18px;
      height: 18px;

      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: white;
        }
      }
    }
  }
}

.insurance-program-card {
  background-color: white;
  border-radius: 4px;
  width: 372px;
  margin: 0 4px;
  position: relative;
  padding-bottom: 10px;
  font-family: 'Inter', 'Roboto', sans-serif;

  @include media-breakpoint-down(md) {
    width: 340px;
  }

  .curve {
    width: 130%;
    height: 200px;
    background: radial-gradient(
      rgba(221, 240, 249, 1) 0%,
      rgba(208, 220, 252, 1) 100%
    );
    position: relative;
    left: -15%;
    border-radius: 0 0 200px 200px;
    z-index: 5;
    top: -10px;
  }
  .included-text {
    background-color: #E1EB80;
    color: black;
    display: flex;
    position: absolute;
    bottom: -30px;
    padding: 8px 24px;
    border-radius: 100px;
    backdrop-filter: blur(7px);
  }
  &-header {
    width: 100%;
    z-index: 9999;
    position: absolute;
    top: 0px;
  }

  &-icon {
    width: 45px;
    height: 45px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 100%;

    img {
      width: 85%;
      height: 85%;
      margin: auto;
    }

    .non-insurance-icon {
      filter: invert(75%) sepia(71%) saturate(5668%) hue-rotate(202deg)
        brightness(97%) contrast(109%);
    }
  }

  &-feature {
    color: $gray-dark;
  }

  .header {
    font-size: 22px !important;
    color: $purple-darkest;
    padding-top: 10px;
  }

  .sub-header {
    color: $purple-my-dark;
    height: 42px;
  }

  .feature-check-icon {
    margin-right: 10px;
    flex-basis: 18px;
    flex-grow: 0;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: $blue-medium;
      }
    }
  }

  .recommended {
    &-pill {
      background-color: $purple-5;
      color: white;
      border-radius: 25px;
      width: 250px;
      margin: auto;
      height: 45px;
    }

    &-spacer {
      height: 45px;
    }
  }

  .continue-button {
    position: absolute;
    bottom: 1rem;
    width: 90%;
    max-width: 300px;

    &-arrow {
      margin-top: -6px;
      width: 18px;
      height: 18px;

      svg {
        height: 16px;
        width: 16px;

        path {
          stroke: white;
        }
      }
    }
  }
}

.navigation {
  &-arrow {
    width: 18px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;

      path {
        stroke: black;
        stroke-width: 1px;
      }
    }
    &-right {
      transform: rotate(180deg);
    }
  }
}
